import headerHeights from 'config/branding/header-heights';
import useDynamicMainContentTop from '@activebrands/core-web/hooks/useDynamicMainContentTop';
import { styled } from '@activebrands/core-web/libs/styletron';
import { LayoutProps } from '@activebrands/core-web/types/layout';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Banner from 'components/Header/Banner';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

const Main = styled('main', {
    flex: '1 0 auto',
});

const DefaultLayout = ({ children, page = {} }: LayoutProps) => {
    const alternateHrefs = page.data?.hrefs || [];
    const pageContent = page.data?.content || {};
    const theme = 'default';
    const banner = pageContent.banner?.[0] || {};
    const bannerTexts = banner?.texts?.map(text => text?.richText || text);
    const hasBanner = bannerTexts?.length > 0;
    const { bannerHeight, headerGapDesktop, headerHeight } = headerHeights;
    const dynamicBannerHeight = hasBanner ? bannerHeight : 0;

    // Here we add 'noPageType' to 404 page if page.type is undefined
    const pageType = page.type || 'noPageType';

    // All themes on template "page" let content of the page fall behind the header
    const contentTopOfPage = ['page', 'noPageType'].includes(pageType);

    // There is a small transparent gap around the header in desktop when contentTopOfPage is true
    const headerStyling = contentTopOfPage
        ? {
              width: '100%',
              backgroundColor: 'transparent',
              paddingTop: ['0', null, null, null, `${headerGapDesktop}px`],
          }
        : {
              width: '100%',
              backgroundColor: 'var(--color-bg-header)',
          };

    // Calculate the distance from the top of the page for content
    const mobileMainContentTop = `0px`;
    const desktopMainContentTop = `${contentTopOfPage ? 0 : headerHeight + dynamicBannerHeight}px`;

    // Update the distance from the top of the page for content
    useDynamicMainContentTop({ desktopMainContentTop, mobileMainContentTop });

    return (
        <Wrapper $style={{ marginTop: [mobileMainContentTop, null, null, null, null, desktopMainContentTop] }}>
            <Header $style={headerStyling} alternateHrefs={alternateHrefs} theme={theme} />
            {hasBanner && (
                <Banner spin={banner?.spin} texts={bannerTexts} theme={banner.theme} to={banner?.link?.url} />
            )}
            <Main>{children}</Main>
            <Footer alternateHrefs={alternateHrefs} />
        </Wrapper>
    );
};

export default DefaultLayout;
